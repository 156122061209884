import { GraphQLClient, request } from 'graphql-request';
import { STORAGE_USER_KEY } from '../modules/session';
let client = null;

function authorizedRequest(query, variables) {
  let token = (typeof localStorage === 'undefined') ? null : localStorage.getItem(STORAGE_USER_KEY);
  if (client === null) {
    client = new GraphQLClient('/graphql', {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
  }
  return client.request(query, variables);
}

export function authenticate(query, variables) {
  return request('/graphql', query, variables)
    .then((data) => data.login)
}

export default authorizedRequest;