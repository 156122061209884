import React from 'react';
import ReactHighcharts from 'react-highcharts';

import { COLORS } from '../helpers/colorPalette';

const StackedColumnChart = ({ categories, data, title, yAxisTitle }) => {
  const config = {
    legend: { enabled: false },
    credits: { enabled: false },
    chart: { type: 'column', height: 300 },
    colors: COLORS,
    title: { text: title },
    xAxis: { categories },
    yAxis: { title: { text: yAxisTitle } },
    plotOptions: { column: { stacking: 'normal' } },
    series: data,
    tooltip: { shared: true }
  };

  return (
    <ReactHighcharts config={config} />
  )
}

export default StackedColumnChart;