import map from 'lodash/map';
import uniq from 'lodash/uniq';
import filter from 'lodash/filter';
import sum from 'lodash/sum';
import times from 'lodash/times';
import moment from 'moment';

export function toTasksColumn(imputations) {
  const categories = uniq(map(imputations, (imp) => imp.task.label));
  const users = uniq(map(imputations, (imp) => imp.userName));
  const data = [];
  users.forEach((user) => {
    const userHoursPerTask = [];
    const userImputations = filter(imputations, (imp) => imp.userName === user);
    categories.forEach((category) => {
      const hours = map(filter(userImputations, (imp) => imp.task.label === category), (imp) => imp.hours);

      userHoursPerTask.push(sum(hours));
    });

    data.push({
      name: user,
      data: userHoursPerTask
    });
  });

  return { categories, data };
}

export function toDayArea(imputations) {
  const categories = getDays(imputations);
  const users = uniq(map(imputations, (imp) => imp.userName));
  const data = [];
  users.forEach((user) => {
    const userHoursPerDay = [];
    const userImputations = filter(imputations, (imp) => imp.userName === user);
    categories.forEach((day) => {
      const hours = map(filter(userImputations, (imp) => imp.day === day), (imp) => imp.hours);

      userHoursPerDay.push(sum(hours));
    });

    data.push({
      name: user,
      data: userHoursPerDay
    });
  });

  return { categories, data }
}
export function timeConvert(mins) {
  var h = Math.floor(mins / 60);
  var m = mins % 60;
  const totalHours = h > 1 ? `${h} hrs` : `${h} hr`;
  const totalMinutes = m > 1 ? `${m} mins` : `${m} min`;

  const labelTexts = `${h > 0 ? `${totalHours} ` : ''}${m > 0 ? totalMinutes : ''}`;

  return labelTexts
}

function getBounds(imputations) {
  let min = null;
  let max = null;

  imputations.forEach((imp) => {
    const dayAsInt = moment(imp.day);
    if (min === null || dayAsInt < min) {
      min = dayAsInt;
    }
    if (max === null || dayAsInt > max) {
      max = dayAsInt;
    }
  })

  return { min, max };
}

function getDays(imputations) {
  const bounds = getBounds(imputations);
  const daysCount = moment(bounds.max).diff(bounds.min, 'days') + 1;

  return times(daysCount, (offset) => moment(bounds.min).add(offset, 'day').format('YYYY-MM-DD'));
}
