import { combineReducers } from 'redux';
import imputations from './imputations';
import imputables from './imputables';
import session from './session';
import calendar from './calendar';
import tasks from './tasks';
import imputableView from './imputableView';
import ui from './ui';

export default combineReducers({
  imputations,
  imputables,
  session,
  calendar,
  tasks,
  imputableView,
  ui
});