import moment from 'moment';

const initialState = {
  isoWeek: moment().isoWeek(),
  focusedDay: moment(),
  selectedDay: null,
  imputationToDuplicate: null
};

const PREV_MONTH = 'PREV_MONTH';
export function prevMonth() {
  return {
    type: PREV_MONTH
  };
}

const PREV_WEEK = 'PREV_WEEK';
export function prevWeek() {
  return {
    type: PREV_WEEK
  };
}

const NEXT_WEEK = 'NEXT_WEEK';
export function nextWeek() {
  return {
    type: NEXT_WEEK
  };
}

const NEXT_MONTH = 'NEXT_MONTH';
export function nextMonth() {
  return {
    type: NEXT_MONTH
  };
}

const CURRENT_WEEK = 'CURRENT_WEEK';
export function currentWeek() {
  return {
    type: CURRENT_WEEK
  };
}

const SELECT_DAY = 'SELECT_DAY';
export function selectDay(day) {
  return {
    type: SELECT_DAY,
    day
  };
}

const DUPLICATE_IMPUTATION = 'DUPLICATE_IMPUTATION';
export function duplicateImputation(imputation) {
  return {
    type: DUPLICATE_IMPUTATION,
    imputation
  };
}

export function endDuplication() {
  return {
    type: DUPLICATE_IMPUTATION,
    imputation: null
  };
}

export default function reduce(state = initialState, action) {
  let focusedDay = state.focufocusedDaysDay;

  switch (action.type) {
    case CURRENT_WEEK:
      return initialState;
    case PREV_MONTH:
      focusedDay = moment(state.focusedDay).subtract(1, 'month');

      return { ...state, focusedDay, isoWeek: focusedDay.isoWeek() };
    case PREV_WEEK:
      focusedDay = moment(state.focusedDay).subtract(1, 'week');

      return { ...state, focusedDay, isoWeek: focusedDay.isoWeek() };
    case NEXT_WEEK:
      focusedDay = moment(state.focusedDay).add(1, 'week');

      return { ...state, focusedDay, isoWeek: focusedDay.isoWeek() };
    case NEXT_MONTH:
      focusedDay = moment(state.focusedDay).add(1, 'month');

      return { ...state, focusedDay, isoWeek: focusedDay.isoWeek() };
    case SELECT_DAY:
      return { ...state, selectedDay: action.day };
    case DUPLICATE_IMPUTATION:
      return {
        ...state,
        imputationToDuplicate: action.imputation
      };
    default:
      return state;
  }
}
