import Imputation from './Imputation';
import React from 'react';
import map from 'lodash/map';

const Imputations = ({ entries, handleDelete }) => {
  return (
    <ul className="list-group" style={{ minHeight: '300px' }}>
      {map(entries, (entry, index) => (
        <Imputation key={index} entry={entry} handleDelete={handleDelete} />
      ))}
    </ul>
  );
};

export default Imputations;
