import './MonthYear.css';

import Icon from './Icon';
import React from 'react';

const MonthYear = ({ month, year }) => (
  <span className="MonthYear">
    <Icon icon="clock" /> <span className="MonthYear__month">{month}</span>
    <span className="MonthYear__year">{year}</span>
  </span>
);

export default MonthYear;
