import React, { Component } from 'react';
import Happytimes from './Happytimes';

class LoginForm extends Component {
  constructor(props) {
    super(props)

    this.submitForm = this.submitForm.bind(this);
  }

  submitForm(e) {
    e.preventDefault();
    const username = this.usernameInput.value;
    const password = this.passwordInput.value;

    this.props.onSubmit(username, password);
  }

  render() {
    const { error } = this.props;

    return (
      <div className="card">
        <div className="card-header text-center">
          <h1>
            <Happytimes />
          </h1>
        </div>
        <form className="LoginForm">
          <div className="card-body text-center">
            {error && (<p className="alert alert-danger">{error}</p>)}
            <div className="form-group">
              <input name="username"
                placeholder="email"
                className="LoginForm__username form-control"
                ref={(input) => this.usernameInput = input} />
            </div>
            <div className="form-group">
              <input name="password"
                type="password"
                placeholder="password"
                className="LoginForm__password form-control"
                ref={(input) => this.passwordInput = input} />
            </div>
            <button type="submit" className="btn btn-outline-secondary" onClick={this.submitForm}>Log in</button>
          </div>
          <div className="card-footer text-right font-italic text-muted">
            First connection: please confirm identity
          </div>
        </form>
      </div>
    )
  }
}

export default LoginForm;