import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

const Icon = ({ className, icon, onClick, style, title }) => (
  <FontAwesomeIcon
    icon={icon}
    onClick={onClick}
    style={style}
    title={title}
    className={className}
  />
);

export default Icon;
