import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown
} from 'reactstrap';

import Icon from './Icon';
import React from 'react';
import { connect } from 'react-redux';
import { signOut } from '../modules/session';

class UserDropdown extends React.Component {
  render() {
    const { links } = this.props;

    return (
      <UncontrolledDropdown nav className="UserDropdown">
        <DropdownToggle nav caret>
          <Icon icon="user" />
        </DropdownToggle>
        <DropdownMenu right>
          {links &&
            links.map((l, index) => (
              <DropdownItem key={index} tag={'a'} href={l.url}>
                <Icon icon={l.icon} /> {l.text}
              </DropdownItem>
            ))}
          <DropdownItem
            onClick={this.props.signOut}
            className="hover-pointer UserDropdown__link"
          >
            <Icon icon="sign-out-alt" title="Sign out" />
            &nbsp; Sign Out
          </DropdownItem>
        </DropdownMenu>
      </UncontrolledDropdown>
    );
  }
}

export default connect(
  null,
  { signOut }
)(UserDropdown);
