import React from 'react';
import map from 'lodash/map';
import sum from 'lodash/sum';
import { timeConvert } from '../helpers/imputationsHelpers';

export default ({ imputations }) => {
  const totalTime = map(imputations, imputation => imputation.hours * 60 + imputation.minutes);
  const total = timeConvert(sum(totalTime));

  return (
    <div className="text-muted" style={{ fontWeight: 'normal', fontSize: '15px' }}>Total Time: {total}</div>
  )
}