import shuffle from 'lodash/shuffle';

export const COLORS = shuffle([
  '#f74119',
  '#ff7849',
  '#ffc82c',
  '#a8c347',
  '#13ce66',
  '#1fb6ff',
  '#3369e7',
  '#7e5bef',
  '#b84592',
  '#ff4f81'
]);


export function colorMultiply(hexColor = '#CCCCCC', amount = 0.4) {
  if (hexColor === null) {
    return '#000000';
  }
  hexColor = hexColor.replace('#', '');
  const rgb = hexColor.match(/(..)(..)(..)/)
  rgb[1] = Math.round((parseInt(rgb[1], 16) * amount));
  rgb[2] = Math.round((parseInt(rgb[2], 16) * amount));
  rgb[3] = Math.round((parseInt(rgb[3], 16) * amount));

  return `rgb(${rgb[1]},${rgb[2]},${rgb[3]})`;
}

export function colorAlpha(hexColor = '#cccccc', alpha = 0.5) {
  if (hexColor === null) {
    return '#000000';
  }
  hexColor = hexColor.replace('#', '');
  const rgb = hexColor.match(/(..)(..)(..)/)
  rgb[1] = Math.round((parseInt(rgb[1], 16)));
  rgb[2] = Math.round((parseInt(rgb[2], 16)));
  rgb[3] = Math.round((parseInt(rgb[3], 16)));

  return `rgba(${rgb[1]},${rgb[2]},${rgb[3]},${alpha})`;
}