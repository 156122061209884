import './App.css';

import React, { Component } from 'react';

import ConnectedContainer from './containers/ConnectedContainer';
import Footer from './components/Footer';
import Happytimes from './components/Happytimes';
import ImputableView from './containers/ImputableView';
import { Nav } from 'reactstrap';
import TimesheetContainer from './containers/TimesheetContainer';
import UserDropdown from './components/UserDropdown';
import { connect } from 'react-redux';
import { endDuplication } from './modules/calendar';

class App extends Component {
  componentDidMount() {
    const { endDuplication } = this.props;

    this.escapeListener = document.addEventListener('keydown', (e) => {
      if (e && e.key === 'Escape') {
        endDuplication();
      }
    });
  }

  componentWillUnmount() {
    if (this.escapeListener) {
      document.removeEventListener(this.escapeListener);
    }
  }

  render() {
    const { connected, user } = this.props.session;
    const rootUrl = user ? user.rootUrl : '/';
    return (
      <div className="App vh-100 d-flex flex-column">
        <nav className="navbar navbar-light bg-light App__navbar fixed-top">
          <a className="navbar-brand App__navbar-brand" href={rootUrl}>
            <Happytimes />
          </a>
          {!!connected && (
            <Nav className="ml-auto" navbar>
              <UserDropdown links={user.links} />
            </Nav>
          )}
        </nav>
        <main className="container App__content flex-grow-1">
          <ConnectedContainer>
            <TimesheetContainer />
            <ImputableView />
          </ConnectedContainer>
        </main>
        <Footer />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  session: state.session,
});

export default connect(mapStateToProps, { endDuplication })(App);
