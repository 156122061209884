import './ImputationForm.css';
import 'react-select/dist/react-select.css';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import React, { Component } from 'react';

import Select from 'react-select';
import map from 'lodash/map';
import moment from 'moment';
import { connect } from 'react-redux';
import { selectTeam } from '../modules/ui';
import { loadImputables } from '../modules/imputables';
import { IMPUTABLES_KEY } from '../modules/imputations';
import uniqBy from 'lodash/uniqBy';
const filterProject = (obj, pattern) => {
  const { label } = obj;
  const re = pattern.replace(/ /g, '.*');

  return label.match(new RegExp(re, 'i'));
};
class ImputationForm extends Component {
  constructor(props) {
    super(props);
    const selectedImputable = props.imputation
      ? {
          value: props.imputation.imputableGuid,
          label: props.imputation.imputable.name,
        }
      : '';
    const selectedHours = props.imputation ? { value: props.imputation.hours, label: props.imputation.hours } : '';
    const selectedMinutes = props.imputation
      ? { value: props.imputation.minutes, label: props.imputation.minutes }
      : '';
    const selectedTask = props.imputation ? { value: props.imputation.taskId, label: props.imputation.task.name } : '';
    this.state = {
      selectedImputable,
      selectedHours,
      selectedTask,
      selectedMinutes,
      submitDisabled: props.imputation === undefined,
      selectedProject: 'latest',
    };
    this.submitForm = this.submitForm.bind(this);
    this.handleSelectChange = this.handleSelectChange.bind(this);
  }

  handleSelectChange(name, option) {
    const { selectedImputable, selectedHours, selectedTask, selectedMinutes } = this.state;
    const values = Object.assign(
      {
        selectedImputable,
        selectedHours,
        selectedMinutes,
        selectedTask,
      },
      { [name]: option }
    );

    const submitDisabled = !(
      (!!values.selectedHours?.value > 0 || !!values.selectedMinutes?.value > 0) &&
      !!values.selectedImputable &&
      !!values.selectedTask
    );
    this.setState({
      [name]: option,
      submitDisabled,
    });
  }

  submitForm(e) {
    e.preventDefault();
    const id = Date.now();
    const day = this.dayInput.value;
    const imputableGuid = this.state.selectedImputable.value;
    const hours = parseInt(this.state.selectedHours?.value, 10) || 0;
    const minutes = parseInt(this.state.selectedMinutes?.value, 10) || 0;
    const taskId = parseInt(this.state.selectedTask.value, 10);
    const note = this.noteInput.value;
    if (!!day && !!imputableGuid && (hours > 0 || minutes > 0) && !!taskId) {
      this.props.onSubmit({
        id,
        day,
        imputableGuid,
        hours,
        minutes,
        taskId,
        note,
      });
    }

    this.noteInput.value = '';
  }

  render() {
    const { day, imputables, tasks, selectTeam, selectedTeam, teams, type } = this.props;
    const { selectedImputable, selectedHours, selectedMinutes, selectedTask } = this.state;
    const imputableGuid = selectedImputable && selectedImputable.value;
    const hours = selectedHours && selectedHours.value;
    const minutes = selectedMinutes && selectedMinutes.value;
    const task = selectedTask && selectedTask.value;
    const fullDay = moment(day).format('dddd, MMM DD, YYYY');
    const imputablesGuid = localStorage.getItem(IMPUTABLES_KEY);
    const imputablesGuidArray = imputablesGuid ? JSON.parse(imputablesGuid) : [];
    const latestImputablesList = imputablesGuidArray?.map((id) =>
      imputables.find((imputable) => imputable.guid === id)
    );
    let options = [];
    const teamId = selectedTeam ? selectedTeam.value : this.props.imputation?.imputable?.teamId;
    if (teamId) {
      options = uniqBy([...latestImputablesList, ...imputables], 'id').filter((imp) => imp.teamId === teamId);
    }

    return (
      <Modal isOpen={true} fade={true} size="lg">
        <ModalHeader>{fullDay}</ModalHeader>
        <ModalBody>
          {day && <input ref={(input) => (this.dayInput = input)} value={day} type="hidden" />}
          <form id="imputation-form">
            {type === 'create' && (
              <div className="form-group">
                <Select
                  name="team"
                  placeholder="Please select a team"
                  value={selectedTeam}
                  onChange={(option) => selectTeam(option)}
                  options={map(teams, (team) => ({
                    value: team.id,
                    label: `${team.name}`,
                  }))}
                />
              </div>
            )}
            {teamId ? (
              <>
                <div className="form-group">
                  <Select
                    name="name"
                    placeholder="Select one of all project"
                    value={imputableGuid}
                    onChange={(option) => this.handleSelectChange('selectedImputable', option)}
                    filterOption={filterProject}
                    options={map(options, (imputable) => ({
                      value: imputable.guid,
                      label: `${imputable.client} - ${imputable.brand} - ${imputable.name}`,
                    }))}
                  />
                </div>

                <div className="form-group">
                  <Select
                    name="task"
                    value={task}
                    onChange={(option) => this.handleSelectChange('selectedTask', option)}
                    placeholder="Select task"
                    options={map(tasks, (task, index) => ({
                      value: task.id,
                      label: task.label,
                    }))}
                  />
                </div>
                <label style={{ color: '#aaa' }}>Select time:</label>
                <div className="form-group row">
                  <div className="col-md-6">
                    <label>Hours</label>
                    <Select
                      name="hours"
                      value={hours}
                      onChange={(option) => this.handleSelectChange('selectedHours', option)}
                      style={{ flex: '.5' }}
                      placeholder="Select hours"
                      options={map([...Array(25).keys()], (hour, index) => ({
                        value: hour,
                        label: `${hour} hour${hour > 1 ? 's' : ''}`,
                      }))}
                    />
                  </div>
                  <div className="col-md-6">
                    <label>Minutes</label>
                    <Select
                      name="minutes"
                      value={minutes}
                      onChange={(option) => this.handleSelectChange('selectedMinutes', option)}
                      style={{ flex: '.5' }}
                      placeholder="Select minutes"
                      options={map(['0', '15', '30', '45'], (minutes, index) => ({
                        value: minutes,
                        label: `${minutes} minute${minutes > 1 ? 's' : ''}`,
                      }))}
                    />
                  </div>
                </div>
                <div className="form-group">
                  <textarea
                    name="note"
                    rows="5"
                    placeholder="Add details to this task"
                    className="form-control"
                    ref={(input) => (this.noteInput = input)}
                    defaultValue={this.props.imputation ? this.props.imputation.note : ''}
                  />
                </div>
              </>
            ) : null}
          </form>
        </ModalBody>
        <ModalFooter className="justify-content-between">
          <Button outline onClick={this.props.onClose}>
            Close
          </Button>
          {this.props.onDelete && (
            <Button outline color="danger" onClick={(e) => this.props.onDelete(this.props.imputation.id, e)}>
              Delete
            </Button>
          )}
          {this.props.onDuplicate && (
            <Button outline onClick={this.props.onDuplicate}>
              Copy
            </Button>
          )}
          <button
            type="submit"
            form="imputation-form"
            className="btn btn-outline-secondary"
            disabled={this.state.submitDisabled}
            onClick={this.submitForm}
          >
            Save
          </button>
        </ModalFooter>
      </Modal>
    );
  }
}

const mapStateToProps = ({ ui, session }) => ({
  selectedTeam: ui.selectedTeam,
  teams: session.user.teams,
  latestImputables: ui.latestImputables,
});

export default connect(mapStateToProps, {
  selectTeam,
  loadImputables,
})(ImputationForm);
