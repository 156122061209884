import React from 'react';
import lowerCase from 'lodash/lowerCase';
import moment from 'moment';

const Happytimes = () => (
  <span className="text-gray Happytimes">
    <span className="text-red">happy</span>{lowerCase(moment().format('dddd'))}
  </span>
)

export default Happytimes;