import request from '../services/graphqlApi';
const initialState = {
  selectedGuid: null,
  imputations: [],
  imputable: null,
};

const SELECTED_GUID = 'SELECTED_GUID';
export function selectGuid(guid) {
  if (guid === null) {
    return { type: SELECTED_GUID, ...initialState };
  }
  const query = `
  query GetImputations($guid: String!)
  {
    getImputableImputations(imputableGuid: $guid) {
      id
      userName
      hours
      minutes
      day
      task {
        id
        label
      }
    }
    imputable(guid: $guid) {
      name
    }
  }`;
  const variables = { guid };
  return (dispatch) => {
    return request(query, variables).then((data) =>
      dispatch({
        type: SELECTED_GUID,
        guid,
        imputable: {
          guid,
          name: data.imputable.name,
        },
        imputations: data.getImputableImputations,
      })
    );
  };
}

function reducer(state = initialState, action) {
  switch (action.type) {
    case SELECTED_GUID:
      const { guid: selectedGuid, imputations, imputable } = action;
      return Object.assign({}, state, { selectedGuid, imputations, imputable });
    default:
      return state;
  }
}

export default reducer;
