import React from 'react';
import { connect } from 'react-redux';

import { toTasksColumn, toDayArea } from '../helpers/imputationsHelpers';
import { selectGuid } from '../modules/imputableView';
import ModalWrapper from "../components/ModalWrapper";
import StackedColumnChart from '../components/StackedColumnChart';
import StackedAreaChart from '../components/StackedAreaChart';
import TotalConsumption from '../components/TotalConsumption';

class ImputableView extends React.Component {
  constructor(props) {
    super(props);

    this.resetSelectedGuid = this.resetSelectedGuid.bind(this);
  }

  resetSelectedGuid() {
    this.props.selectGuid(null);
  }

  render() {
    const { imputations, selectedGuid, imputable } = this.props.imputableView;
    const title = imputable ? imputable.name : selectedGuid;
    const imputationsUpdate = imputations.map(imputation => {
      let { hours, minutes } = imputation;
      minutes = minutes ? minutes : 0;
      let total = Number(hours + minutes / 60)
      return {
        ...imputation,
        hours: Number(total),
      };
    })

    const stackedColumnParams = {
      title: 'By task',
      yAxisTitle: 'Hours',
      ...(toTasksColumn(imputationsUpdate))
    };
    const stackedAreaParams = {
      title: 'By day',
      yAxisTitle: 'Hours',
      ...(toDayArea(imputationsUpdate))
    };

    return (
      <div className="ImputableView">
        <ModalWrapper
          title={<div>{title} <TotalConsumption imputations={imputations} /></div>}
          isOpen={selectedGuid != null}
          size='lg'
          onClose={() => this.resetSelectedGuid()}>
          <StackedColumnChart {...stackedColumnParams} />
          <StackedAreaChart { ...stackedAreaParams} />
        </ModalWrapper>
      </div>
    )
  }
}

const mapStateToProps = (state) => (
  {
    imputableView: state.imputableView
  }
)

export default connect(mapStateToProps, { selectGuid })(ImputableView);