import React, { Component } from 'react';

import DayHeader from './DayHeader';
import Imputations from './Imputations';
import { Table } from 'reactstrap';
import filter from 'lodash/filter';
import map from 'lodash/map';
import moment from 'moment';
import styled from 'styled-components';
import sum from 'lodash/sum';
import times from 'lodash/times';
import { timeConvert } from '../helpers/imputationsHelpers';

const DAYS_IN_WEEK = 7;

const TimesheetFooter = styled.tfoot`
  font-size: 0.8rem;
`;

class Timesheet extends Component {
  constructor(props) {
    super(props);

    this.selectDay = this.selectDay.bind(this);
    this.deleteImputation = this.deleteImputation.bind(this);
  }

  selectDay(day) {
    this.props.handleSelectDay(day);
  }

  deleteImputation(id) {
    this.props.handleDelete(id);
  }

  render() {
    const today = moment().format('YYYY-MM-DD');
    const {
      from,
      to,
      imputations,
      imputationToDuplicate,
      weekOfYear
    } = this.props;
    const daysCount = moment(to).diff(from, 'days') + 1;
    const weeksCount = Math.ceil(daysCount / DAYS_IN_WEEK);
    const days = times(daysCount, offset =>
      moment(from)
        .add(offset, 'day')
        .format('YYYY-MM-DD')
    );
    let totalTimeLabel = [];
    days.forEach(day => {
      const imputationsForDay = filter(imputations, { day });
      const hoursInMins = sum(map(imputationsForDay, imputation => imputation.hours)) * 60
      const mins = sum(map(imputationsForDay, imputation => imputation.minutes));
      const total = timeConvert(hoursInMins + mins);
      totalTimeLabel.push(total);
    })
  

    return (
      <div className="Timesheet">
        <Table bordered size="sm" className="mb-0">
          <thead>
            <tr>
              {times(weeksCount, offset => (
                <th
                  key={offset}
                  className={`DayHeader ${offset === 1 ? 'text-red' : ''}`}
                  colSpan={DAYS_IN_WEEK}
                >
                  Week {weekOfYear + offset - 1}
                </th>
              ))}
            </tr>
            <tr>
              {map(days, (day, offset) => (
                <DayHeader
                  key={offset}
                  day={day}
                  current={day === today}
                  allowSelectDay={imputationToDuplicate === null}
                  handleSelectDay={this.selectDay}
                  pasteImputation={this.props.handlePasteImputation}
                  imputationToDuplicate={imputationToDuplicate}
                />
              ))}
            </tr>
          </thead>
          <tbody>
            <tr>
              {map(days, (day, offset) => {
                const entries = filter(imputations, entry => entry.day === day);
                return (
                  <td key={offset} onClick={() => this.selectDay(day)}>
                    <Imputations
                      entries={entries}
                      handleDelete={this.deleteImputation}
                    />
                  </td>
                );
              })}
            </tr>
          </tbody>
          <TimesheetFooter>
            <tr>
              {map(totalTimeLabel, (label, index) => {
                return (
                  <th className="text-left" key={index}>
                    Total: {label}
                  </th>
                );
              })}
            </tr>
          </TimesheetFooter>
        </Table>
      </div>
    );
  }
}

export default Timesheet;
