import { Button, ButtonGroup } from 'reactstrap';

import Icon from './Icon';
import React from 'react';

const CalendarNavigation = ({
  prevMonthHandler,
  prevWeekHandler,
  currentHandler,
  nextWeekHandler,
  nextMonthHandler
}) => (
  <ButtonGroup>
    <Button outline onClick={prevMonthHandler}>
      <Icon icon="angle-double-left" /> month
    </Button>
    <Button outline onClick={prevWeekHandler}>
      <Icon icon="angle-left" /> week
    </Button>
    <Button outline onClick={currentHandler}>
      today
    </Button>
    <Button outline onClick={nextWeekHandler}>
      week <Icon icon="angle-right" />
    </Button>
    <Button outline onClick={nextMonthHandler}>
      month <Icon icon="angle-double-right" />
    </Button>
  </ButtonGroup>
);

export default CalendarNavigation;
