import findIndex from 'lodash/findIndex';
import request from '../services/graphqlApi';

const initialState = [];
export const IMPUTABLES_KEY = 'imputables';
export const REPLACE_IMPUTATION = 'REPLACE_IMPUTATION';
function replaceImputation(temporaryId, imputation) {
  return {
    type: REPLACE_IMPUTATION,
    temporaryId,
    imputation,
  };
}

export const ADD_IMPUTATION = 'ADD_IMPUTATION';
export function addImputation(imputation) {
  const query = `
    mutation AddImputation($userId: Int!, $imputation: ImputationInput!) {
      addImputation(userId: $userId, imputation: $imputation) {
        id
        imputableGuid
        taskId
        day
        hours
        minutes
        note
        imputable {
          name
          color
          client
          brand
        }
        task {
          id
          label
        }
      }
    }`;

  return (dispatch) => {
    dispatch({
      type: ADD_IMPUTATION,
      imputation: {
        ...imputation,
        saving: true,
        imputable: {
          color: '#cccccc',
        },
        task: {
          label: 'Saving...',
        },
      },
    });
    // delete temporary imputation id given by frontend
    const temporaryId = imputation.id;
    delete imputation.id;
    const variables = {
      userId: imputation.userId,
      imputation,
    };
    return request(query, variables).then((data) => dispatch(replaceImputation(temporaryId, data.addImputation)));
  };
}

export const REMOVE_IMPUTATION = 'REMOVE_IMPUTATION';
export function removeImputation(id) {
  const query = `
    mutation DeleteImputation($id: String!) {
      deleteImputation(id: $id)
    }
  `;
  const variables = { id };

  return (dispatch) => {
    request(query, variables).then((data) =>
      dispatch({
        type: REMOVE_IMPUTATION,
        id,
      })
    );
  };
}

export const LOAD_USER_IMPUTATIONS = 'LOAD_USER_IMPUTATIONS';
export function loadUserImputations(userId, from, to) {
  const query = `
    query QueryImputations($userId: Int!, $from: String, $to: String) {
      queryImputations(from: $from, to: $to, userIds: [$userId]) {
        id
        imputableGuid
        taskId
        imputable {
          name
          color
          client
          brand
          teamId
        }
        task {
          label
        }
        day
        hours
        minutes
        note
      }
    }
  `;
  const variables = { userId, from, to };

  return (dispatch) => {
    request(query, variables).then((data) =>
      dispatch({
        type: LOAD_USER_IMPUTATIONS,
        imputations: data.queryImputations,
      })
    );
  };
}

export const DECREMENT_HOURS = 'DECREMENT_HOURS';
export function decrementHours(id) {
  const query = `
    mutation DecrementImputation($id: String!) {
      decrementImputation(id: $id) {
        id
      }
    }`;

  return (dispatch) => {
    return request(query, { id }).then((data) =>
      dispatch({
        type: DECREMENT_HOURS,
        id,
      })
    );
  };
}

export const UPDATE_IMPUTATION = 'UPDATE_IMPUTATION';
export function updateImputation(id, imputation) {
  const query = `
    mutation updateImputation($id: String!, $imputation: ImputationUpdate!) {
      updateImputation(id: $id, imputation: $imputation) {
        id
        imputableGuid
        taskId
        day
        hours
        minutes
        note
        imputable {
          name
          color
          client
          brand
          teamId
        }
        task {
          id
          label
        }
      }
    }`;

  return (dispatch) => {
    // run optimistic update
    dispatch(
      replaceImputation(id, {
        ...imputation,
        id,
        saving: true,
        imputable: {
          color: '#cccccc',
        },
        task: {
          label: 'Saving...',
        },
      })
    );

    return request(query, { id, imputation }).then((data) => dispatch(replaceImputation(id, data.updateImputation)));
  };
}

export const CHANGE_TASK = 'CHANGE_TASK';
export function changeTask(id, taskId) {
  const query = `
    mutation updateImputation($id: String!, $imputation: ImputationUpdate!) {
      updateImputation(id: $id, imputation: $imputation) {
        id
        taskId
        task {
          id
          label
        }
      }
    }`;

  return (dispatch) => {
    return request(query, { id, imputation: { taskId } }).then((data) =>
      dispatch({
        type: CHANGE_TASK,
        id,
        taskId,
        task: data.updateImputation.task,
      })
    );
  };
}

export const INCREMENT_HOURS = 'INCREMENT_HOURS';
export function incrementHours(id) {
  const query = `
    mutation IncrementImputation($id: String!) {
      incrementImputation(id: $id) {
        id
      }
    }`;

  return (dispatch) => {
    return request(query, { id }).then((data) =>
      dispatch({
        type: INCREMENT_HOURS,
        id,
      })
    );
  };
}

// TODO: Don't check size everytime we insert, just slice the array before writing to localStorage
function reduceImputations(state = initialState, action) {
  const MAX_IMPUTABLES = 10;
  let id = null;
  let index = -1;
  let imputablesGuid = localStorage.getItem(IMPUTABLES_KEY);
  let imputablesGuidArray = imputablesGuid ? JSON.parse(imputablesGuid) : [];
  const { imputation } = action;

  switch (action.type) {
    case LOAD_USER_IMPUTATIONS: {
      imputablesGuid = localStorage.getItem(IMPUTABLES_KEY);
      imputablesGuidArray = imputablesGuid ? JSON.parse(imputablesGuid) : [];
      // if list is not already full, fill it with latest imputations loaded from server
      if (imputablesGuidArray.length < MAX_IMPUTABLES) {
        action.imputations.forEach((imputation) => {
          if (imputablesGuidArray.length < MAX_IMPUTABLES) {
            if (imputablesGuidArray.indexOf(imputation.imputableGuid) === -1 && imputation.imputableGuid) {
              imputablesGuidArray.push(imputation.imputableGuid);
            }
          }
        });
        localStorage.setItem(IMPUTABLES_KEY, JSON.stringify(imputablesGuidArray));
      }
      return action.imputations;
    }

    case ADD_IMPUTATION:
      imputablesGuid = localStorage.getItem(IMPUTABLES_KEY);
      imputablesGuidArray = imputablesGuid ? JSON.parse(imputablesGuid) : [];
      if (imputablesGuidArray.indexOf(imputation.imputableGuid) === -1 && imputation.imputableGuid) {
        imputablesGuidArray.unshift(imputation.imputableGuid);
        if (imputablesGuidArray.length > MAX_IMPUTABLES) {
          imputablesGuidArray.pop();
        }
      }
      // set the new array to localstorage
      localStorage.setItem(IMPUTABLES_KEY, JSON.stringify(imputablesGuidArray));

      return [...state, { ...imputation }];

    case REPLACE_IMPUTATION:
      id = action.temporaryId;
      index = findIndex(state, (imputation) => imputation.id === id);
      imputablesGuid = localStorage.getItem(IMPUTABLES_KEY);
      imputablesGuidArray = imputablesGuid ? JSON.parse(imputablesGuid) : [];
      if (imputation.imputableGuid) {
        // if duplicate, remove it
        const duplicateIndex = imputablesGuidArray.indexOf(imputation.imputableGuid);
        if (duplicateIndex !== -1) {
          imputablesGuidArray.splice(duplicateIndex, 1);
        }
        // add the new one
        imputablesGuidArray.unshift(imputation.imputableGuid);
        if (imputablesGuidArray.length > MAX_IMPUTABLES) {
          imputablesGuidArray.pop();
        }
      }
      localStorage.setItem(IMPUTABLES_KEY, JSON.stringify(imputablesGuidArray));
      if (index >= 0) {
        return [...state.slice(0, index), action.imputation, ...state.slice(index + 1)];
      }
      return state;

    case REMOVE_IMPUTATION:
      id = action.id;
      index = findIndex(state, (imputation) => imputation.id === id);
      return [...state.slice(0, index), ...state.slice(index + 1)];

    case DECREMENT_HOURS:
      id = action.id;
      index = findIndex(state, (imputation) => imputation.id === id);
      if (index >= 0) {
        const imputation = Object.assign({}, state[index]);
        if (imputation.hours > 1) {
          imputation.hours -= 1;
        }
        return [...state.slice(0, index), imputation, ...state.slice(index + 1)];
      }
      return state;

    case INCREMENT_HOURS:
      id = action.id;
      index = findIndex(state, (imputation) => imputation.id === id);
      if (index >= 0) {
        const imputation = Object.assign({}, state[index]);
        if (imputation.hours < 24) {
          imputation.hours += 1;
        }
        return [...state.slice(0, index), imputation, ...state.slice(index + 1)];
      }
      return state;

    case CHANGE_TASK:
      id = action.id;
      const { taskId, task } = action;
      index = findIndex(state, (imputation) => imputation.id === id);
      if (index >= 0) {
        const imputation = Object.assign({}, state[index], { taskId, task });
        return [...state.slice(0, index), imputation, ...state.slice(index + 1)];
      }
      return state;

    default:
      return state;
  }
}

export default reduceImputations;
