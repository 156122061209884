
const initialState = {
  selectedTeam: null,
  isLoading: false
};

export const SELECT_TEAM = 'SELECT_TEAM';
export const LOADING = 'LOADING';

export function selectTeam(team) {
  return {
    type: SELECT_TEAM,
    team,
  };
}
export function isLoading(loading) {
  return {
    type: LOADING,
    loading,
  };
}
export function latestImputables(imputables) {
  return {
    type: LATEST_IMPUTABLES,
    imputables,
  };
}

const LOAD_IMPUTABLES = 'LOAD_IMPUTABLES';
export const LATEST_IMPUTABLES = 'LATEST_IMPUTABLES';

export function loadImputables(teamIds) {
  const query = `
  query($teamIds: [Int]!) {
    imputables(teamIds: $teamIds) {
      guid
      type
      id
      name
      color
      client
      brand
    }
  }`;
  return (dispatch) => {
    dispatch(isLoading(true));
    request(query, { teamIds })
      .then((data) => sortBy(data.imputables, ['client', 'brand', 'name']))
      .then((imputables) => {
        dispatch({
          type: LOAD_IMPUTABLES,
          imputables,
        });
        dispatch(isLoading(false));
      });
  };
}

function ui(state = initialState, action) {
  switch (action.type) {
    case SELECT_TEAM:
      return {
        ...state,
        selectedTeam: action.team,
      };
    case LOADING:
      return {
        ...state,
        isLoading: action.loading,
      };
    case LATEST_IMPUTABLES:
      return {
        ...state,
        latestImputables: action.imputables,
      };
    default:
      return state;
  }
}

export default ui
