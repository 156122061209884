import './DayHeader.css';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import classNames from 'classnames';
import moment from 'moment';

class DayHeader extends React.Component {
  constructor(props) {
    super(props);

    this.selectDay = this.selectDay.bind(this);
    this.pasteImputation = this.pasteImputation.bind(this);
  }

  selectDay(e, day) {
    e.preventDefault();

    this.props.handleSelectDay(day);
  }

  pasteImputation(e, imputation, day) {
    e.preventDefault();

    this.props.pasteImputation(imputation, day);
  }

  render() {
    const { day, current, allowSelectDay, imputationToDuplicate } = this.props;
    const dayName = moment(day).format('ddd');
    const number = moment(day).format('DD');
    const classes = classNames({
      'text-red': current
    });

    return (
      <th style={{ minWidth: '11rem' }} className="DayHeader" id={day}>
        <span className={classes}>
          {dayName} {number}
        </span>
        &nbsp;
        {allowSelectDay ? (
          <FontAwesomeIcon
            icon="plus"
            className="hover-pointer text-red"
            onClick={e => this.selectDay(e, day)}
          />
        ) : (
          <FontAwesomeIcon
            icon="paste"
            className="hover-pointer text-red"
            onClick={e => this.pasteImputation(e, imputationToDuplicate, day)}
          />
        )}
      </th>
    );
  }
}

export default DayHeader;
