import './Imputation.css';

import { Col, Row } from 'reactstrap';
import React, { Component } from 'react';
import { changeTask, decrementHours, incrementHours, updateImputation } from '../modules/imputations';

import Icon from './Icon';
import ImputationForm from './ImputationForm';
import { colorAlpha } from '../helpers/colorPalette';
import { connect } from 'react-redux';
import { duplicateImputation } from '../modules/calendar';
import { selectGuid } from '../modules/imputableView';

class Imputation extends Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.deleteImputation = this.deleteImputation.bind(this);
    this.decrementHours = this.decrementHours.bind(this);
    this.incrementHours = this.incrementHours.bind(this);
    this.changeTask = this.changeTask.bind(this);
    this.updateImputation = this.updateImputation.bind(this);
    this.selectGuid = this.selectGuid.bind(this);
    this.state = {
      popoverOpen: false,
      selectedTask: '',
    };
  }

  toggle(e) {
    e && e.stopPropagation();

    this.setState({
      popoverOpen: !this.state.popoverOpen,
    });
  }

  deleteImputation(id, event) {
    event.preventDefault();
    this.setState({
      popoverOpen: false,
    });
    this.props.handleDelete(id);
  }

  decrementHours(id) {
    this.props.decrementHours(id);
  }

  updateImputation(id, imputation) {
    delete imputation.id;
    this.props.updateImputation(id, imputation);
    this.setState({
      popoverOpen: false,
    });
  }

  changeTask(id, taskOption) {
    const taskId = taskOption && taskOption.value;
    if (taskId > 0) {
      this.props.changeTask(id, taskOption.value);
    }
  }

  incrementHours(id) {
    this.props.incrementHours(id);
  }

  selectGuid(e, guid) {
    e.stopPropagation();
    this.props.selectGuid(guid);
  }

  render() {
    const { popoverOpen } = this.state;
    const { entry, tasks, imputables } = this.props;
    const opacity = entry.saving ? 0.5 : 1;

    const imputationStyle = {
      height: `${Math.max(.9, entry.hours) * 4}rem`,
      opacity,
      backgroundColor: colorAlpha(entry.imputable.color, 0.2),
    };

    const nameStyle = {
      color: entry.imputable.color,
    };

    const buttonStyle = {
      backgroundColor: 'transparent',
      borderColor: 'none',
      color: entry.imputable.color,
      padding: 0,
    };

    const totalHours = entry.hours > 1 ? `${entry.hours} hrs` : `${entry.hours} hr`;
    const totalMinutes = entry.minutes > 1 ? `${entry.minutes} mins` : `${entry.minutes} min`;

    const labelTexts = `${entry.hours > 0 ? `${totalHours} ` : ''}${entry.minutes > 0 ? totalMinutes : ''}`;

    return (
      <li id={`Imputation-${entry.id}`} className="Imputation list-group-item" style={imputationStyle}>
        <div onClick={this.toggle} onDoubleClick={this.toggle} className="hover-pointer Imputation__clickable">
          <span className="Imputation__name" style={nameStyle}>
            {entry.imputable.brand} - {entry.imputable.name}
          </span>
          {entry.note && (
            <p className="Imputation__note">
              <Icon icon="info-circle" className="hover-help" title={entry.note} />
            </p>
          )}
        </div>
        <Row noGutters={true}>
          <Col style={nameStyle}>{labelTexts}</Col>
          <Col className="text-right" sm={2}>
            <Icon
              icon="search"
              className="hover-pointer"
              title={`Click for ${entry.imputable.name} overview`}
              style={buttonStyle}
              onClick={(e) => this.selectGuid(e, entry.imputableGuid)}
            />
          </Col>
        </Row>
        {popoverOpen && (
          <ImputationForm
            day={entry.day}
            imputation={entry}
            imputables={imputables}
            tasks={tasks}
            onSubmit={(imp) => this.updateImputation(entry.id, imp)}
            onDelete={this.deleteImputation}
            onClose={this.toggle}
            onDuplicate={() => this.props.duplicateImputation(entry) && this.toggle()}
          />
        )}
      </li>
    );
  }
}

const mapStateToProps = ({ tasks, imputables }) => ({
  tasks,
  imputables,
});

export default connect(mapStateToProps, {
  duplicateImputation,
  decrementHours,
  incrementHours,
  selectGuid,
  changeTask,
  updateImputation,
})(Imputation);
