import React from 'react';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';

class ModalWrapper extends React.Component {
  render() {
    const { isOpen, title, size } = this.props;

    return (
      <Modal isOpen={isOpen} toggle={this.props.onClose} fade={true} size={size}>
        <ModalHeader toggle={this.props.onClose}>{title}</ModalHeader>
        <ModalBody>
          {this.props.children}
        </ModalBody>
      </Modal>
    )
  }
}

export default ModalWrapper;