import request, { authenticate } from '../services/graphqlApi';

import { loadImputables } from './imputables';
import { loadTasks } from './tasks';
import { selectTeam } from './ui';

export const STORAGE_USER_KEY = 'id_token';
const initialState = {
  user: {},
  connected: false,
  connecting: false,
  error: null,
};

function signedInWorkflow(user, dispatch) {
  dispatch(signedIn(user));
  dispatch(
    loadImputables(
      user.id,
      user.teams?.map((team) => team.id)
    )
  );
  dispatch(loadTasks());
}

export function signingIn(username, password) {
  const query = `
    query($username: String!, $password: String!) {
      login(username: $username, password: $password) {
        id
        name
        teams {
          id
          name
        }
        token
        rootUrl
        links {
          icon
          url
          text
        }
      }
    }
  `;

  return (dispatch) => {
    dispatch(connecting());
    return authenticate(query, { username, password })
      .then((user) => {
        signedInWorkflow(user, dispatch);
      })
      .catch((e) => {
        console.log(e);
        return dispatch(signingError('Invalid credentials. Please try again.'));
      });
  };
}

const SIGN_OUT = 'SIGN_OUT';
export function signOut() {
  localStorage.removeItem(STORAGE_USER_KEY);
  return {
    type: SIGN_OUT,
  };
}

const CONNECTING = 'CONNECTING';
function connecting() {
  return {
    type: CONNECTING,
  };
}

export const SIGNED_IN = 'SIGNED_IN';
function signedIn(user) {
  // store in localStorage
  localStorage.setItem(STORAGE_USER_KEY, user.token);
  // send action
  return {
    type: SIGNED_IN,
    user,
  };
}

export function signInThroughLocalStorage() {
  return (dispatch) => {
    dispatch(connecting());

    const token = localStorage.getItem(STORAGE_USER_KEY);
    if (!!token) {
      const query = `
        query {
          me {
            id
            name
            teams {
              id
              name
            }
            token
            rootUrl
            links {
              icon
              url
              text
            }
          }
        }
      `;
      return request(query).then(({ me }) => {
        signedInWorkflow(me, dispatch);
      });
    } else {
      return dispatch(signOut());
    }
  };
}

const SIGNING_ERROR = 'SIGNING_ERROR';
function signingError(error) {
  return {
    type: SIGNING_ERROR,
    error,
  };
}

export default function reduce(state = initialState, action) {
  switch (action.type) {
    case CONNECTING:
      return {
        connected: false,
        connecting: true,
        error: null,
      };
    case SIGNING_ERROR:
      return {
        connected: false,
        connecting: false,
        error: action.error,
      };
    case SIGNED_IN:
      return {
        connected: true,
        connecting: false,
        error: null,
        user: action.user,
      };
    case SIGN_OUT:
      return initialState;

    default:
      return state;
  }
}
