import React, { Component } from 'react';
import { connect } from 'react-redux';
import { signingIn, signInThroughLocalStorage } from '../modules/session';

import LoginForm from "../components/LoginForm";
import Loading from '../components/Loading';

// it renders its children only if connected
class ConnectedContainer extends Component {
  componentDidMount() {
    this.props.signInThroughLocalStorage();
  }

  render() {
    const { connected, connecting, error } = this.props.session;

    if (connecting) {
      return (<Loading />);
    }
    if (connected) {
      return (
        <div>
          {this.props.children}
        </div>
      );
    } else {
      return (
        <div className="row justify-content-center">
          <div className="col-4">
            <LoginForm onSubmit={this.props.signingIn} error={error} />
          </div>
        </div>);
    }
  }
}

const mapStateToProps = (state) => ({
  session: state.session
})

export default connect(mapStateToProps, { signingIn, signInThroughLocalStorage })(ConnectedContainer);
