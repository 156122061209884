import { Col, Row } from 'reactstrap';
import React, { Component } from 'react';
import {
  addImputation,
  loadUserImputations,
  removeImputation
} from '../modules/imputations';
import {
  currentWeek,
  endDuplication,
  nextMonth,
  nextWeek,
  prevMonth,
  prevWeek,
  selectDay
} from '../modules/calendar';

import CalendarNavigation from '../components/CalendarNavigation';
import ImputationForm from '../components/ImputationForm';
import MonthYear from '../components/MonthYear';
import ScrollWrapper from '../components/ScrollWrapper';
import Timesheet from '../components/Timesheet';
import { connect } from 'react-redux';
import moment from 'moment';
import times from 'lodash/times';

class TimesheetContainer extends Component {
  constructor(props) {
    super(props);

    this.addImputation = this.addImputation.bind(this);
    this.removeImputation = this.removeImputation.bind(this);
    this.previousMonth = this.previousMonth.bind(this);
    this.previousWeek = this.previousWeek.bind(this);
    this.nextWeek = this.nextWeek.bind(this);
    this.nextMonth = this.nextMonth.bind(this);
    this.currentWeek = this.currentWeek.bind(this);
    this.selectDay = this.selectDay.bind(this);
    this.duplicateImputation = this.duplicateImputation.bind(this);
    this.pasteImputation = this.pasteImputation.bind(this);
  }

  loadImputations() {
    this.props.loadUserImputations(
      this.props.user.id,
      moment(this.props.focusedDay)
        .startOf('isoWeek')
        .add(-1, 'week')
        .format('YYYY-MM-DD'),
      moment(this.props.focusedDay)
        .endOf('isoWeek')
        .format('YYYY-MM-DD')
    );
  }

  componentDidMount() {
    this.loadImputations();
  }

  componentDidUpdate(prevProps) {
    if (this.props.isoWeek !== prevProps.isoWeek) {
      this.loadImputations();
    }
  }

  duplicateImputation(imputation, count) {
    this.props.endDuplication();
    if (imputation && count) {
      if (count > 0) {
        times(count, offset => {
          const day = moment(imputation.day)
            .add(offset + 1, 'days')
            .format('YYYY-MM-DD');
          const id = Date.now() + '-' + day;
          const data = { ...imputation, day, id };
          delete data.imputable;
          delete data.task;
          this.addImputation(data);
        });
      }
    }
  }

  pasteImputation(imputation, day) {
    if (imputation && day) {
      const id = Date.now() + '-' + day;
      const data = { ...imputation, day, id };
      delete data.imputable;
      delete data.task;
      this.addImputation(data);
    }
  }

  previousMonth(e) {
    e.preventDefault();
    this.props.prevMonth();
  }

  previousWeek(e) {
    e.preventDefault();
    this.props.prevWeek();
  }

  nextWeek(e) {
    e.preventDefault();
    this.props.nextWeek();
  }

  nextMonth(e) {
    e.preventDefault();
    this.props.nextMonth();
  }

  currentWeek(e) {
    e.preventDefault();
    this.props.currentWeek();
  }

  selectDay(day) {
    this.props.selectDay(day);
  }

  addImputation(imputation) {
    const { id, name } = this.props.user;
    // send addImputation message
    imputation.userId = id;
    imputation.userName = name;
    this.props.addImputation(imputation);
    // reset selectedDay
    this.props.selectDay(null);
  }

  removeImputation(imputation) {
    this.props.removeImputation(imputation);
  }

  render() {
    const {
      selectedDay,
      isoWeek,
      focusedDay,
      imputations,
      imputables,
      tasks,
      imputationToDuplicate
    } = this.props;
    const translatedMoment = moment(focusedDay);
    const from = moment(translatedMoment)
      .startOf('isoWeek')
      .add(-1, 'week')
      .format('YYYY-MM-DD');
    const weekOfYear = isoWeek;
    const firstDayOfWeek = moment(translatedMoment);
    const month = firstDayOfWeek.format('MMMM');
    const year = firstDayOfWeek.format('YYYY');
    const to = translatedMoment.endOf('isoWeek').format('YYYY-MM-DD');

    return (
      <div>
        <h1>
          <MonthYear month={month} year={year} />
        </h1>
        <Row>
          <Col>
            <CalendarNavigation
              prevMonthHandler={this.previousMonth}
              prevWeekHandler={this.previousWeek}
              currentHandler={this.currentWeek}
              nextWeekHandler={this.nextWeek}
              nextMonthHandler={this.nextMonth}
            />
          </Col>
        </Row>
        <ScrollWrapper
          scrollTo={firstDayOfWeek.format('YYYY-MM-DD')}
          imputationsCount={imputations.length}
        >
          <Timesheet
            weekOfYear={weekOfYear}
            from={from}
            to={to}
            imputables={imputables}
            imputations={imputations}
            selectedDay={selectedDay}
            handleSelectDay={this.selectDay}
            handleDelete={this.removeImputation}
            imputationToDuplicate={imputationToDuplicate}
            handlePasteImputation={this.pasteImputation}
          />
        </ScrollWrapper>
        {selectedDay !== null && (
          <ImputationForm
            type='create'
            day={selectedDay}
            imputables={imputables}
            tasks={tasks}
            onSubmit={this.addImputation}
            onClose={() => this.selectDay(null)}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  user: state.session.user,
  imputationToDuplicate: state.calendar.imputationToDuplicate,
  selectedDay: state.calendar.selectedDay,
  isoWeek: state.calendar.isoWeek,
  focusedDay: state.calendar.focusedDay,
  imputations: state.imputations,
  imputables: state.imputables,
  tasks: state.tasks
});

const mapDispatchToProps = {
  addImputation,
  removeImputation,
  loadUserImputations,
  prevMonth,
  prevWeek,
  currentWeek,
  nextWeek,
  nextMonth,
  selectDay,
  endDuplication
};

export default connect(mapStateToProps, mapDispatchToProps)(TimesheetContainer);
