import sortBy from 'lodash/sortBy';

import request from '../services/graphqlApi';
import { isLoading, LATEST_IMPUTABLES } from './ui';

const initialState = [];

const LOAD_IMPUTABLES = 'LOAD_IMPUTABLES';

export function loadImputables(userId, teamIds) {
  const query = `
  query($userId: Int!) {
    imputables(userId: $userId) {
      guid
      type
      id
      name
      color
      client
      brand
      teamId
    }
  }`;
  return (dispatch) => {
    dispatch(isLoading(true));
    request(query, { userId, teamIds })
      .then((data) => {
        dispatch({ type: LATEST_IMPUTABLES, imputables: data.imputables });
        return sortBy(data.imputables, ['client', 'brand', 'name']);
      })
      .then((imputables) => {
        dispatch({
          type: LOAD_IMPUTABLES,
          imputables,
        });
        dispatch(isLoading(false));
      })
      .catch((err) => console.error(err));
  };
}

function reduceImputables(state = initialState, action) {
  switch (action.type) {
    case LOAD_IMPUTABLES:
      return action.imputables;

    default:
      return state;
  }
}

export default reduceImputables;
