import React from 'react';
import ReactHighcharts from 'react-highcharts';

import sum from 'lodash/sum';

import { COLORS } from '../helpers/colorPalette';

const StackedColumnChart = ({ categories, data, title, yAxisTitle }) => {
  const config = {
    legend: {
      enabled: true,
      labelFormatter: function () {
        const total = sum(this.yData);
        return `${this.name}: ${total} hour(s)`;
      }
    },
    colors: COLORS,
    title: { text: title },
    credits: { enabled: false },
    chart: { type: 'area', height: 300 },
    xAxis: { categories },
    yAxis: { title: { text: yAxisTitle } },
    plotOptions: { area: { stacking: 'normal' } },
    series: data,
    tooltip: { shared: true }
  };

  return (
    <ReactHighcharts config={config} />
  )
}

export default StackedColumnChart;