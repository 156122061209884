import React, { useEffect } from 'react';

import styled from 'styled-components';

const Wrapper = styled.div`
  margin-left: ${({ ml }) => ml};
  overflow-x: scroll;
  border-left: 1px solid #dee2e6;
  border-right: 1px solid #dee2e6;
`;

const ScrollWrapper = ({ imputationsCount = 0, scrollTo, children }) => {
  useEffect(
    () => {
      document
        .getElementById(scrollTo)
        .scrollIntoView({ inline: 'start', behavior: 'smooth' });
    },
    [scrollTo, imputationsCount]
  );
  return <Wrapper className="mt-3">{children}</Wrapper>;
};

export default ScrollWrapper;
