import 'bootstrap/dist/css/bootstrap.min.css';
import './index.css';

import {
  faAngleDoubleLeft,
  faAngleDoubleRight,
  faAngleLeft,
  faAngleRight,
  faCalendarAlt,
  faClock,
  faCopy,
  faEnvelope,
  faGraduationCap,
  faInfoCircle,
  faPaste,
  faPlus,
  faSearch,
  faSignOutAlt,
  faUser,
} from '@fortawesome/free-solid-svg-icons';

import App from './App';
import { Provider } from 'react-redux';
import React from 'react';
import ReactDOM from 'react-dom';
import { library } from '@fortawesome/fontawesome-svg-core';
import store from './store';
import { QueryClient, QueryClientProvider } from 'react-query';

const icons = [
  faAngleDoubleLeft,
  faAngleDoubleRight,
  faAngleLeft,
  faAngleRight,
  faPaste,
  faCopy,
  faCalendarAlt,
  faEnvelope,
  faGraduationCap,
  faSignOutAlt,
  faUser,
  faInfoCircle,
  faSearch,
  faClock,
  faPlus,
];

const queryClient = new QueryClient();

library.add(...icons);

ReactDOM.render(
  <Provider store={store}>
    <QueryClientProvider client={queryClient}>
      <App />
    </QueryClientProvider>
  </Provider>,
  document.getElementById('root')
);
