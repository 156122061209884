import request from '../services/graphqlApi';

const LOAD_TASKS = 'LOAD_TASKS';
export function loadTasks() {
  const query = `{
    tasks(scope: "timesheet") {
      id
      label
    }
  }`;

  return dispatch => {
    request(query).then(data =>
      dispatch({
        type: LOAD_TASKS,
        tasks: data.tasks
      })
    );
  };
}

function reduceTasks(state = [], action) {
  switch (action.type) {
    case LOAD_TASKS:
      return action.tasks;
    default:
      return state;
  }
}
export default reduceTasks;
